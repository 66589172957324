import React from 'react';

import { Link } from 'react-router-dom';
import './testCategory.scss';
const TestCategory = (props) => {
  return (
    <div className='col'>
      <Link to={props.path} className='btn btn-primary btn-block'>
        {props.title}
      </Link>
    </div>
  );
};
export default TestCategory;
