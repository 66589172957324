import React from 'react';
import './question.scss';

const Question = (props) => {
  return (
    <div style={props.st} className='btn btn-primary btn-block'>
      {props.title}
    </div>
  );
};
export default Question;
