import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

class Home extends Component {
  render() {
    return (
      <Container>
        <div>Login</div>

        <div>Test</div>
      </Container>
    );
  }
}

export default Home;
