import React, { Component } from 'react';
import Tests from '../../components/tests/tests';

class TestsPage extends Component {
  render() {
    return (
      <div>
        <Tests />
      </div>
    );
  }
}

export default TestsPage;
